
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ExpandableCell',
  props: {
    text: {
      type: String as PropType<string>,
      default: '',
    },
    width: {
      type: Number as PropType<number>,
      default: 100,
    },
  },
  setup(props) {
    const style = computed(() => `width: ${props.width}px`);

    return {
      style,
    };
  },
});
