import FingerprintJS from '@fingerprintjs/fingerprintjs';
import md5 from 'blueimp-md5';
import { User } from '@/store/auth/types';

export type Method = 'GET' | 'POST';

export type Payload = {
  [key: string]: string | number | boolean
};

export type Sructure = {
  structure: string
}

export type Result = {
  errorText: string,
  errorType: number,
  body?: any[],
  body2: Sructure[],
}

export type LoginResult = {
  [index: number]: any[];
}

export const COMMON_ENDPOINT = 'test';
export const LOGIN_ENDPOINT = 'login';
export const BASE_URL = 'https://host2.medsafe.tech/';
export const POSTFIX = 'api/';

const API_URL = `${BASE_URL}${POSTFIX}${COMMON_ENDPOINT}`;
const LOGIN_URL = `${BASE_URL}${POSTFIX}${LOGIN_ENDPOINT}`;

const defaultHeaders = {
  'Content-Type': 'application/json',
};
const loginHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const getImei = async function getImei() {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();
  return md5(result.visitorId);
};

export default class Api {
  IMEI: Promise<string>;

  constructor() {
    this.IMEI = getImei();
  }

  async get(payload: Payload): Promise<Result> {
    const toReturn: Result = await this.request(`${API_URL}?json=${JSON.stringify(payload)}`, 'GET');
    console.log(payload, toReturn);
    return toReturn;
  }

  async post(
    payload: Payload,
    type: boolean | null = null,
  ): Promise<Result> {
    const method = 'POST';
    let toReturn: Result;
    if (!type) {
      toReturn = await this.request(`${API_URL}?json=${JSON.stringify(payload)}`, method);
    } else {
      const body = `json=${JSON.stringify(payload)}`;
      toReturn = await this.request(`${API_URL}`, method, body);
    }
    console.log(payload, toReturn);
    return toReturn;
  }

  async login(
    payload: Payload,
  ): Promise<LoginResult> {
    const method = 'POST';
    const body = `json=${JSON.stringify(payload)}`;
    const toReturn: LoginResult = await this.request(`${LOGIN_URL}`, method, body, loginHeaders);
    console.log(payload, toReturn, toReturn[0]);
    return toReturn[0];
  }

  async all(method: Method, payload: Payload): Promise<Result> {
    return method === 'GET' ? this.get(payload) : this.post(payload);
  }

  async request(
    url: string,
    method: Method,
    body: FormData | null | string = null,
    headers: HeadersInit | null = null,
  ): Promise<any> {
    try {
      const response = await fetch(url, {
        method,
        headers: headers || defaultHeaders,
        body,
      });
      const result = await response.json();
      return result;
    } catch (error) {
      return error;
    }
  }

  async sendLargeJson(payload: Payload) {
    try {
      const response = await this.post({ json: JSON.stringify(payload) }, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getFile(hash: string) {
    try {
      const response = await fetch(`${BASE_URL}${hash}`);
      if (response.ok) {
        const result = await response.blob();
        return result;
      }
      return false;
    } catch (error) {
      return error;
    }
  }

  async uploadFile(formData: FormData, url: string) {
    try {
      const upload = await this.request(`https://host2.medsafe.tech/api/${url}/`, 'POST', formData, {
        'Content-Type': 'multipart/form-data',
      });
      return upload;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}
