export interface IModalComponent {
  [key: string]: {
    header: string,
    altHeader?: string,
  }
}

export const modalComponentsMap: IModalComponent = {
  OrgEditorModal: {
    header: 'Подробности организации',
  },
  ContactEditorModal: {
    header: 'Редактировать контакт',
  },
};
